<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Withdrawal Transaction Details(Crypto)</h2>
        </div>

        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
        </div>
      </div>
      <div v-if="data" class="table-settings mb-4">
        <div class="d-flex align-items-center justify-content-between">
          <!-- <div v-if="data.status === 'SUBMITTED'" class>
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="
                  RequestConfirmation(
                    'Do you really want to approve this transaction?',
                    () => {
                      RequestConfirmation(
                        'Sure to approve this transaction? This is an irreversible action',
                        ApproveTransaction
                      );
                    }
                  )
                "
            >Approve Transaction</button>
          </div>-->
          <!-- <div v-if="data.status === 'FAILED'" class>
            <button
              type="button"
              class="btn btn-sm btn-secondary text-nowrap"
              @click="
                  RequestConfirmation(
                    'Do you really want to retry this transaction?',
                    () => {
                      RequestConfirmation(
                        'Sure to retry transaction? This is an irreversible action',
                        ProcessTransaction
                      );
                    }
                  )
                "
            >Retry Transaction</button>
          </div>-->
          <div v-if="['SUBMITTED', 'FAILED'].includes(data.status)" class>
            <button
              type="button"
              class="btn btn-sm btn-dark text-nowrap"
              @click="
                  RequestConfirmation(
                    'Do you really want to manually certify this transaction?',
                    () => {
                      RequestConfirmation(
                        'Sure to set transaction manually? This is an irreversible action',
                        ManualTransaction
                      );
                    }
                  )
                "
            >Manual Success</button>
          </div>
          <div class>
            <button
              type="button"
              class="btn btn-sm btn-danger text-nowrap"
              v-if="['SUBMITTED', 'FAILED'].includes(data.status)"
              @click="
                  RequestConfirmation(
                    'Do you really want to decline this withdrawal?',
                    () => {
                      RequestConfirmation(
                        'Sure to decline this transaction? This is an irreversible action',
                        () => {
                        sureConfirmDecline = true;
                      }
                      );
                    }
                  )
                "
            >Decline Withdrawal</button>
          </div>
        </div>
      </div>
      <div v-if="data" class="row">
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h4 class="text-gray">Created At</h4>
              <h5 class="fw-bolder">{{ data.created_at }}</h5>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Status</h4>
                <h6>{{ data.status }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Fee</h4>
                <h6>{{ data.fee }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Amount</h4>
                <h6>{{ data.amount }}</h6>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-4">
                <h4 class="text-gray">UserId</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.id }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Email</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.email }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Name</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>
                    {{ data.User.Profile.first_name }}
                    {{ data.User.Profile.last_name }}
                  </h6>
                </router-link>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Comment</h4>
                <h6>{{ data.description ? data.description : "No Description" }}</h6>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Remark</h4>
                <h6>{{ data.remark ? data.remark : "No Remark" }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Crypto Account Information</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">Coin name</h4>
                <h6>{{ data.CryptoAccount.coin_name }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.CryptoAccount.coin_type }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Network</h4>
                <h6>{{ data.CryptoAccount.network }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Wallet Address</h4>
                <h6>{{ data.CryptoAccount.wallet_address }}</h6>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
        

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Payout Amount</h4>
                <h6>{{ data.payout_amount }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Tag</h4>
                <h6>{{ data.tag }}</h6>
              </div>
            </div>
            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Reference</h4>
                <h6>{{ data.reference }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Wallet Address</h4>
                <h6>{{ data.wallet_address }}</h6>
              </div>
            </div>
            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Rate</h4>
                <h6>{{ data.rate }}</h6>
              </div>
            </div>
    
          </div>
        </div>-->
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Fiat Currency Information</h6>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.FiatCurrency.name }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Code</h4>
                <h6>{{ data.FiatCurrency.code }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">We Buy</h4>
                <h6>{{ data.FiatCurrency.we_buy }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">We Sell</h4>
                <h6>{{ data.FiatCurrency.we_sell }}</h6>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 mt-5 mt-md-0">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Payment Attempts</h6>
            </div>

            <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tag</th>
                    <th>Reference</th>
                    <th>FLW ID</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dt, index) in data.PaymentOutwards" :key="index">
                    <td>{{ dt.id }}</td>
                    <td>{{ dt.tag }}</td>
                    <td>{{ dt.reference }}</td>
                    <td>{{ dt.flw_tx_id }}</td>
                    <td>{{ dt.status }}</td>
                    <td>{{ dt.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>-->
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h1 class="text-danger text-center">No Data!</h1>
        </div>
      </div>
    </section>
  </HourglassLoader>
  <div class="coin-withdrawal-detail-response" v-if="sureConfirmDecline">
    <SecondaryModal :modalActive="sureConfirmDecline" @close="sureConfirmDecline = false">
      <div class style="width: 24rem">
        <div class="card-body d-flex justify-content-center align-items-center">
          <DefaultLoader :loading="loading">
            <div class>
              <h5 class="card-title my-3 text-center">Reason for Decline</h5>
              <!-- <button
                @click.prevent="customComment = !customComment"
                type="button"
                class="btn btn-secondary"
              >
              </button>-->
              <div>
                <span v-if="customComment">Custom Response</span>
                <span v-else>Automated Response</span>
              </div>

              <div class="mb-3 response-list">
                <div class v-if="customComment">
                  <div class="my-2">
                    <textarea
                      class="form-control"
                      placeholder="Leave a comment here"
                      id="remark"
                      v-model="declineComment"
                      style="height: 100px; min-width: 100%"
                      required
                    ></textarea>
                    <!-- <label for="remark">Remark</label> -->
                  </div>

                  <div class="d-flex justify-content-between">
                    <button
                      @click="DeclineTransaction(declineComment)"
                      type="button"
                      class="btn btn-primary"
                    >Submit</button>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="d-flex flex-column my-1"
                    v-for="remark in automatedResponses"
                    :key="remark.id"
                  >
                    <div
                      class="decline-response text-white btn p-1"
                      @click="DeclineTransaction(remark.response)"
                    >{{ remark.response }}</div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <button
                  @click="sureConfirmDecline = false"
                  type="button"
                  class="btn btn-light me-auto"
                >Cancel</button>
                <h6 class="cursor-pointer mx-4" @click.prevent="customComment = !customComment">
                  <span
                    v-if="!customComment"
                    class="text-underline text-info"
                  >Enter a custom response</span>
                  <span v-else class="text-underline text-info">Use Automated response</span>
                </h6>
              </div>
            </div>
          </DefaultLoader>
        </div>
      </div>
    </SecondaryModal>
  </div>
</template>
  
  <script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import SecondaryModal from "@/components/modal/SecondaryModal.vue";
import { onBeforeMount, ref, defineComponent, inject, watchEffect } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "WithdrawalDetail",
  components: {
    HourglassLoader,
    SecondaryModal,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const customComment = ref(false);
    const declineComment = ref("");

    const RequestConfirmation = inject("RequestConfirmation");

    const sureConfirmDecline = ref(false);

    const route = useRoute();

    const automatedResponses = ref([]);

    watchEffect(() => {
      document.body.classList.toggle(
        "no-scroll-on-decline-modal",
        sureConfirmDecline.value
      );
    });

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/withdrawals/crypto/transactions/${route.params.coinWithdrawalId}`
          // `/admin/coin/inward/transactions/${route.params.coinWithdrawalId}`
        );

        const getAutomatedResponses = await apiGet(
          `/admin/misc/automated/response`
        );

        automatedResponses.value =
          getAutomatedResponses.data.data.automatedResponse;

        data.value = response.data.data.transaction;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/approve`
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ProcessTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/retry`,
          { status: "PROCESSING" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction Processing",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ManualTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/crypto/transactions/${route.params.coinWithdrawalId}/manual`,
          {
            status: "MANUAL",
          }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction now marked successful",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve manually: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const DeclineTransaction = async (comment: string) => {
      // const { value: text } = await Swal.fire({
      //   input: "textarea",
      //   inputLabel: "Enter Reason/Comment for declining",
      //   inputPlaceholder: "Type your reason/comment here...",
      //   inputAttributes: {
      //     "aria-label": "Type your reason/comment here",
      //   },
      //   showCancelButton: true,
      // });

      // if (!text) {
      //   return;
      // }

      loading.value = true;
      try {
        await apiPost(
          `admin/withdrawals/crypto/transactions/${route.params.coinWithdrawalId}/decline`,
          { comments: comment, status: "FAILED" }
        );
        sureConfirmDecline.value = false;

        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,
      ProcessTransaction,
      ManualTransaction,
      declineComment,
      automatedResponses,
      customComment,
      sureConfirmDecline,
      RequestConfirmation,
      route,
      data,
      loading,
    };
  },
});
</script>
  
  <style >
.coin-withdrawal-detail-response .question-border {
  border: 2px solid #f1c5f9;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  padding: 2rem;
}

.coin-withdrawal-detail-response .decline-response {
  background-color: rgb(95, 116, 54);
}

.coin-withdrawal-detail-response .response-list {
  height: 12rem !important;
  overflow-y: auto;
}

.coin-withdrawal-detail-response .cursor-pointer {
  cursor: pointer;
}

.no-scroll-on-decline-modal {
  overflow: hidden;
}
</style>
  